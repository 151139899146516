import type { ReactNode } from 'react';

import LoginHeader from '@app/LoginPage/LoginHeader';

type LoginPageContainerProps = {
  children: ReactNode;
};

const LoginPageContainer = ({ children }: LoginPageContainerProps) => {
  return (
    <div className="flex h-full items-center justify-center bg-coolGray-900">
      <div className="mb-2 flex w-84 flex-col rounded bg-white p-4">
        <LoginHeader />
        {children}
      </div>
    </div>
  );
};

export default LoginPageContainer;
