import { useState } from 'react';

import useEffectAbortable from '@hooks/useEffectAbortable';
import useRequest from '@hooks/useRequestWithLogging';
import type { SsoSettings } from '@snorkel/api/lib';
import { ssoApi } from '@utils/api/serverRequests';

import { defaultSsoSettings } from '../../AdminPage/AdminSettings/SSOSettingsPage/hooks/useSAMLSettings';

type SSOState = {
  ssoSettings: SsoSettings;
  loading: boolean;
};

const useSSOStatus = () => {
  const request = useRequest();
  const [ssoState, setSSOState] = useState<SSOState>({
    loading: true,
    ssoSettings: defaultSsoSettings,
  });

  const loadSSO = async (signal: AbortSignal) => {
    const resp = await request(ssoApi.getSsoSettingsSsoSettingsGet, {});

    if (!resp || signal.aborted) {
      setSSOState(prev => ({
        ...prev,
        loading: false,
      }));

      return;
    }

    setSSOState({
      loading: false,
      ssoSettings: resp,
    });
  };

  useEffectAbortable(loadSSO, []);

  return ssoState;
};

export default useSSOStatus;
