import moment from 'moment';
import type { FormEvent } from 'react';
import { useEffect, useState } from 'react';

import useAuthContext from '@hooks/useAuthContext';
import useStateFromEvent from '@hooks/useStateFromEvent';
import useUntypedRequest from '@hooks/useUntypedRequest';
import Button, { ButtonThemes } from '@snorkel/coral/components/Button';
import Input from '@snorkel/coral/components/Input';
import { InputSizes } from '@snorkel/coral/components/InputContainer/types';
import { push } from '@utils/api';
import { getReturnUrl } from '@utils/auth/returnUrlHelper';

const LoginForm = () => {
  const [username, setUsername] = useStateFromEvent('');
  const [password, setPassword] = useStateFromEvent('');
  const [invalid, setInvalid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const request = useUntypedRequest();
  const { setAccessToken } = useAuthContext();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      if (loading) {
        return;
      }

      if (e) {
        e.preventDefault();
      }

      setLoading(true);

      const result = await request('login', {
        body: { username, password },
        method: 'POST',
        fallback: {
          loggedin: false,
        },
      });

      if (!result || !result.access_token || result.loggedin === false) {
        setInvalid(true);

        return;
      }

      const { access_token: token, refresh_token, expires_in } = result;

      setAccessToken({
        expiry: moment().add(expires_in, 'seconds').valueOf(),
        accessToken: token,
      });

      await request('refresh-token', {
        body: { refresh_token },
        surfaceError: true,
      });

      push(getReturnUrl());
    } catch (err: any) {
      setInvalid(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInvalid(false);
  }, [username, password]);

  const disableLogin = !username || !password;

  return (
    <form onSubmit={handleSubmit}>
      <div className="px-2">
        <Input
          id="username"
          type="text"
          label="Username"
          value={username}
          onChange={setUsername}
          error={invalid}
          fullWidth
          data-cy="username-field"
          size={InputSizes.medium}
          className="mb-4"
        />
        <div className="mb-3">
          <Input
            id="password"
            type="password"
            label="Password"
            value={password}
            onChange={setPassword}
            error={invalid ? 'Incorrect username or password' : false}
            fullWidth
            data-cy="password-field"
            size={InputSizes.medium}
          />
        </div>
        <div className="mb-4 text-coolGray-800">
          If you do not remember your password, please contact your
          administrator.
        </div>
      </div>
      <div className="flex justify-end border-t border-gray-200 pt-4">
        <Button
          theme={ButtonThemes.primary}
          disabled={disableLogin}
          loading={loading}
          data-cy="login-button"
        >
          <div className="p-1">Login</div>
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
