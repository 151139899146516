import type { ReactNode } from 'react';

type SeparatorProps = {
  children?: ReactNode;
  className?: string;
};

const Separator = ({ children, className }: SeparatorProps) => {
  return (
    <div className={`flex items-center ${className || ''}`}>
      <div className="h-px flex-1 bg-gray-200" />
      {children && (
        <div className="mx-2 flex-none text-gray-700">{children}</div>
      )}
      <div className="h-px flex-1 bg-gray-200" />
    </div>
  );
};

export default Separator;
