export const getReturnUrl = (): string => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const nextUrl = urlSearchParams.get('nextURL');

  let item = '/';

  if (nextUrl) {
    const url = decodeURIComponent(nextUrl as string);

    if (url.startsWith('/')) {
      item = url;
    } else {
      const urlObject = new URL(url);

      if (urlObject.origin === window.location.origin) {
        item = url;
      }
    }
  }

  return item === '/admin/login' ? '/' : item;
};

export default null;
