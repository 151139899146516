import type { ChangeEvent } from 'react';
import { useState } from 'react';

export type SetStateFromEvent = (
  event: ChangeEvent<{ value: unknown }> | any,
) => void;

const useStateFromEvent = <T = any>(initial: T): [T, SetStateFromEvent] => {
  const [state, setState] = useState<T>(initial);

  const setStateFromEvent: SetStateFromEvent = (
    event: ChangeEvent<{ value: unknown }> | any,
  ) => {
    if (event && event.target) {
      const { target: { value } = { value: event } } = event;
      setState(value);
    } else {
      setState(event);
    }
  };

  return [state, setStateFromEvent];
};

export default useStateFromEvent;
