import { normalizeUrl } from '@utils/normalizeUrl';

const LoginHeader = () => {
  return (
    <div className="mb-4">
      <img
        src={normalizeUrl('/static/logo_dark.png')}
        alt="bg"
        className="-ml-3 -mt-3 mb-3 h-11"
      />
      <img
        src={normalizeUrl('/static/images/bubbles.svg')}
        alt="bg"
        className="px-2"
        style={{ height: 90 }}
      />
    </div>
  );
};

export default LoginHeader;
