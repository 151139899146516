import useRequest from '@hooks/useRequestWithLogging';
import type { SsoSettings } from '@snorkel/api/lib';
import { SsoType } from '@snorkel/api/lib';
import { ssoApi } from '@utils/api/serverRequests';

const useSSORedirectURL = (ssoSettings: SsoSettings, inviteLink?: string) => {
  const request = useRequest();

  const getSSORedirectURL = async () => {
    const currentURL = new URL(window.location.href);
    const params = new URLSearchParams(currentURL.search);

    const nextURLParam = params.get('nextURL');
    let nextURL = '';

    if (typeof nextURLParam === 'string') {
      if (nextURLParam.startsWith('/')) {
        nextURL = nextURLParam;
      } else {
        const urlObject = new URL(nextURLParam);

        if (urlObject.origin === window.location.origin) {
          nextURL = nextURLParam;
        }
      }
    }

    params.delete('sso_login_error');
    const ssoLinkResp =
      ssoSettings.sso_type === SsoType.Saml
        ? await request(
            ssoApi.startSamlAuthFlowSsoSamlStartGet,
            {},
            `${window.location.pathname}?${params}`,
            `${inviteLink || ''}`,
          )
        : await request(
            ssoApi.startOidcSsoOidcStartGet,
            {},
            nextURL || undefined,
            `${inviteLink || ''}`,
          );

    return ssoLinkResp?.login;
  };

  return getSSORedirectURL;
};

export default useSSORedirectURL;
